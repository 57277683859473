/* Home page css layout */

.home {
  background-color: #800080;
  color: #fff;
  /* Spacing from top and bottom */
  padding: 20px 0;
}

.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.home-header-title {
  font-size: 24px;
  font-weight: 600;
  color: #4b0082;
}

.home-header-button {
  background-color: #4b0082;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.home-header-button:hover {
  background-color: #fff;
  color: #4b0082;
  border: 2px solid #4b0082;
}

.home-header-button:active {
  transform: scale(0.95);
}

.home-header-button:focus {
  outline: none;
}

.home-button {
  background-color: #4b0082;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.home-button:hover {
  background-color: #fff;
  color: #4b0082;
  border: 2px solid #4b0082;
}

.home-button:active {
  transform: scale(0.95);
}

.home-button:focus {
  outline: none;
}

.home-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.home-body {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.home-body > div {
  margin-bottom: 20px;
}

.home-body > h2 {
  font-size: 24px;
  font-weight: 600;
  color: #4b0082;
}

.home-body > p {
  font-size: 16px;
  font-weight: 400;
  color: #4b0082;
}

.home-body-text {
  font-size: 16px;
  font-weight: 400;
  color: #4b0082;
}